<template>
  <v-row>

    <v-col cols="12" sm="8">
      <search-box
          :hidden-clear="false"
          label="Cliente"
          popup-label="Selecione um Cliente"
          :id.sync="value.idCliente"
          :nome.sync="value.nome_idCliente"
          :value="cliente"
          :loader-fn="loaderClientes"
          item-key="id"
          item-text="nome">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Cliente encontrado
          </p>
        </template>
      </search-box>
    </v-col>
    <!-- v-col cols="12" sm="4">
      <lookup-dominio label="Status Pagamento"
                      v-model="value.status"
                      :type="lookups.status"
                      :hidden-clear="false"/>
    </v-col --> 
    <v-col cols="12" sm="4">
        <v-select
          v-model="value.status"
          :items="statusPagamento"
          item-text="valor"
          item-value="key"
          label="Status Pagamento"
          :clearable="true"
          dense
        ></v-select> 
    </v-col> 
    <v-col cols="12" sm="4">
      <search-box
          label="Evento"
          popup-label="Selecione um Evento"
          :id.sync="value.idCompEvento"
          :descricao.sync="value.descCompEvento"
          :value="evento"
          :loader-fn="loaderEventos"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Evento encontrado
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field dense label="Ano Competência" 
            v-model="value.anoCompetencia"
            :rules="[
                v => this.$validators.number.greaterThanOrEquals(v, 2000),
                v => this.$validators.number.lessThanOrEquals(v, this.anoPermitidoFim())
            ]" type="number"/>
    </v-col>  
    <v-col cols="12" sm="4">
      <search-box
          label="Etapa"
          popup-label="Selecione uma Etapa"
          :id.sync="value.idCompEtapa"
          :descricao.sync="value.descCompEtapa"
          :value="etapa"
          :loader-fn="loaderEtapas"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhuma Etapa encontrada
          </p>
        </template>
      </search-box>
    </v-col>
  </v-row>
  
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import {findAll as findAllClientes} from "../../../../api/clientes";

import {findAll as findAllEventos} from "../../../../api/eventos";
import {findAll as findAllEtapas} from "../../../../api/etapas";

import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import moment from 'moment';

export default {
  name: "RelatorioControleRecebimentoForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      /*lookups: {
        status: ItemDeDominio.STS_PAGAMENTO,
      },*/
      statusPagamento: [
        {key: "A", valor: "Aberto"},
        {key: "F", valor: "Fechado"},
        {key: "O", valor: "Outros"},
      ],
      loaderClientes(page, search) {
        return findAllClientes(page, {
          nome: search
        })
      },
      loaderEventos(page, search) {
        return findAllEventos(page, {
          descricao: search
        })
      },
      loaderEtapas(page, search) {
        return findAllEtapas(page, {
          descricao: search
        })
      }, 
    }
  },
  computed: {
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nome_idCliente
      }
    },
    evento() {
      if(this.value.idCompEvento == null) return null;
      return {
        id: this.value.idCompEvento,
        descricao: this.value.descCompEvento
      }
    },
    etapa() {
      if(this.value.idCompEtapa == null) return null;
      return {
        id: this.value.idCompEtapa,
        descricao: this.value.descCompEtapa
      }
    },
    anoAtual: function () {
      if (typeof this.value.competicao_ano === "undefined"){
        this.value.competicao_ano = moment().format('YYYY');
      }
    },
  },
  methods: {
    anoPermitidoFim() {
        return parseInt(moment().format('YYYY'))+2;
    },  
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>